.download-button {
  width: 200px;
  height: 55px;
  border-radius: 50px;
  border: none;
  box-shadow: 1px 1px rgba(252, 84, 134, 0.614);
  padding: 5px 10px;
  background: rgb(247, 32, 89);
  background: linear-gradient(
    160deg,
    rgb(247, 32, 89) 0%,
    rgb(255, 42, 98) 5%,
    rgb(252, 36, 94) 11%,
    rgb(250, 67, 116) 57%,
    rgb(255, 90, 134) 71%
  );
  color: #fff;
  font-family: "poppins", sans-serif;
  font-weight: 505;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  filter: drop-shadow(0 0 10px rgba(251, 31, 104, 0.568));
  transition: 0.5s linear;
}

.download-button .mysvg {
  display: none;
}

.download-button:hover {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.5s linear;
}

.download-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.download-button:hover .download-text {
  display: none;
}

.download-button:hover .mysvg {
  display: inline;
}

.download-button:hover::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3.5px solid transparent;
  border-top: 3.5px solid #fff;
  border-right: 3.5px solid #fff;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.download-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background: rgba(255, 42, 98, 0.5);
  filter: blur(15px);
  opacity: 0;
  transition: opacity 0.5s linear;
}

.download-button:hover::after {
  opacity: 1;
  transition: opacity 0.5s linear;
}
