@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hidescroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .container {
  width: 1170px;
} */

.heading{
  font-family: "Poppins", sans-serif;
  /* font-weight: 500; */

  width: 100%;
  padding: 15px;
}

@media (min-width: 768px) {
  .how_big {
    content: "";
    background: #facadf;
    margin: 0 auto;
    left: 0;
    right: 0;
    border-radius: 507.5px;
    transform: rotate(20deg);
    
    display: flex;
    text-align: center;
    position: relative;
    flex-direction: column;
  }

  .how_big_text {
    transform: rotate(-20deg);
  }

  .who_we_sec::after {
    content: "";
    height: 115%;
    width: 50%;
    opacity: 0.5;
    transform: rotate(160deg);
    border-radius: 251px;
    background-color: #facadf;
    position: absolute;
    left: -10%;
    top: -10%;
    z-index: -1;
  }
  .who_we_sec2::after {
    content: "";
    height: 85%;
    width: 50%;
    opacity: 0.5;
    transform: rotate(160deg);
    border-radius: 251px;
    background-color: #facadf;
    position: absolute;
    left: 50%;
    top: 20%;
    z-index: -1;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.glowing-button {
  box-shadow: 0 2px 4px 2px rgb(252, 80, 143);
}

.navbar-with-box-shadow {
  box-shadow: 0 2px 4px 2px rgba(254, 207, 225, 0.589);
}

.fade-up{
  z-index: 100;
}

.delete-button{
  background-color: rgb(239, 68, 68) !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}
.cancel-button{
  border: 1px solid gray !important;
  background-color: rgb(236, 236, 236) !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  color: rgb(82, 81, 81) !important;
}